import { ChevronDoubleDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { FC, useMemo, useState } from 'react';
import { connectMenu } from 'react-instantsearch-dom';

type CategoryRefinementProps = {
  className?: string;
  label: string;
} & any;

const CategoryRefinement: FC<CategoryRefinementProps> = connectMenu(
  ({ className, label, items, refine, limit, showMore }) => {
    const [showAll, setShowAll] = useState(!showMore);

    const orderedAndSplitItems = useMemo(
      () =>
        items
          .sort((a, b) =>
            (a.label as string).localeCompare(b.label, undefined, {
              numeric: true,
            })
          )
          .slice(0, showAll ? undefined : limit),
      [items, limit, showAll]
    );

    return (
      <>
        {!!items?.length && (
          <div className={clsx(className, 'mt-4')}>
            <div className="font-semibold text-primary-300 mb-2">{label}</div>
            {orderedAndSplitItems.map((item) => (
              <div
                key={item.label}
                className={clsx(
                  'flex items-center text-sm mt-1 w-full',
                  'cursor-pointer',
                  item.isRefined && 'font-semibold'
                )}
              >
                <input
                  id={`${label}-${item.label}`}
                  type="radio"
                  checked={item.isRefined}
                  onChange={() => refine(item.value)}
                  className="focus:ring-primary-300 h-4 w-4 text-primary-300 border-gray-500"
                />
                <label
                  htmlFor={`${label}-${item.label}`}
                  className="ml-2 block text-sm text-gray-700 truncate"
                >
                  {item.label}
                </label>
                <div className="flex-1 text-right ml-2">{item.count}</div>
              </div>
            ))}
            {!showAll && limit < items.length && (
              <button
                className="flex items-center text-primary mt-1"
                onClick={() => setShowAll(true)}
                type="button"
              >
                <ChevronDoubleDownIcon className="h-4 w-4 mr-2" /> Arata toate
              </button>
            )}
          </div>
        )}
      </>
    );
  }
);

export default CategoryRefinement;
